import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import AutoFixHighRoundedIcon from '@mui/icons-material/AutoFixHighRounded';
import CodeIcon from '@mui/icons-material/Code';
import QueryStatsRoundedIcon from '@mui/icons-material/QueryStatsRounded';
import WorkIcon from '@mui/icons-material/Work';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';
import SchoolIcon from '@mui/icons-material/School';
import FavoriteIcon from '@mui/icons-material/Favorite';
import DataObjectIcon from '@mui/icons-material/DataObject';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';

const items = [
  {
    icon: <SchoolIcon />,
    title: 'Education',
    description:
      'Graduated from University of Bucharest in 2019 with a degree in Computer Science.',
  },
  {
    icon: <WorkIcon />,
    title: 'Current job',
    description:
      'I have been working as a Java Server Software Engineer for the past 8 years.',
  },
  {
    icon: <FavoriteIcon />,
    title: 'Passions',
    description:
      'I love cars, technology, and sports. I am a big fan of driving and ride on the bike on weekends.',
  },

  {
    icon: <CodeIcon />,
    title: 'Java',
    description:
        'I demonstrate a strong understanding of Java and its ecosystem. I have proficiency in Java 8.',
  },

  {
    icon: <DataObjectIcon />,
    title: 'Microservices',
    description:
        'I have extensive experience in building and maintaining microservices, from building a microservice up to measuring and improving the performance.',
  },

  {
    icon: <IntegrationInstructionsIcon />,
    title: 'Mobile Applications',
    description:
        'I recently started developing mobile applications using React Native and I am very excited about it.',
  },

];

export default function Highlights() {
  return (
    <Box
      id="highlights"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        color: 'white',
        bgcolor: '#06090a',
      }}
    >
      <Container
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: '100%', md: '60%' },
            textAlign: { sm: 'left', md: 'center' },
          }}
        >
          <Typography component="h2" variant="h4">
            About me
          </Typography>
          <Typography variant="body1" sx={{ color: 'grey.400' }}>
          </Typography>
        </Box>
        <Grid container spacing={2.5}>
          {items.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Stack
                direction="column"
                color="inherit"
                component={Card}
                spacing={1}
                useFlexGap
                sx={{
                  p: 3,
                  height: '100%',
                  border: '1px solid',
                  borderColor: 'grey.800',
                  background: 'transparent',
                  backgroundColor: 'grey.900',
                }}
              >
                <Box sx={{ opacity: '50%' }}>{item.icon}</Box>
                <div>
                  <Typography fontWeight="medium" gutterBottom>
                    {item.title}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'grey.400' }}>
                    {item.description}
                  </Typography>
                </div>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
