import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function FAQ() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Container
      id="faq"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Typography
        component="h2"
        variant="h4"
        color="text.primary"
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        Contact
      </Typography>
      <Box sx={{ width: '100%' }}>
        <Accordion
          expanded={expanded === 'panel1'}
          onChange={handleChange('panel1')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1d-content"
            id="panel1d-header"
          >
            <Typography component="h3" variant="subtitle2">
              Email
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: '100%', md: '70%' } }}
            >
              You can reach out to me for support or feedback by emailing at
              <Link> devadrianchesnoiu@gmail.com </Link>
             .
            </Typography>
          </AccordionDetails>
        </Accordion>
          <Accordion
              expanded={expanded === 'panel2'}
              onChange={handleChange('panel2')}
          >
              <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1d-content"
                  id="panel1d-header"
              >
                  <Typography component="h3" variant="subtitle2">
                      Linkedin
                  </Typography>
              </AccordionSummary>
              <AccordionDetails>
                  <Typography
                      variant="body2"
                      gutterBottom
                      sx={{ maxWidth: { sm: '100%', md: '70%' } }}
                  >
                      You can follow me on Linkedin at
                      <Link> https://www.linkedin.com/in/adrian-mihai-chesnoiu-11a3578b/ </Link>
                      .
                  </Typography>
              </AccordionDetails>
          </Accordion>
      </Box>

    </Container>
  );
}
