import logo from './logo.svg';
import './App.css';
import LandingPage from "./LandingPage";
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";
import Terms from "./Terms";
import Privacy from "./Privacy";

function App() {
  return (
    <div className="App">


        <Router>
            <Routes>
                <Route exact path="/" element={<LandingPage />} />
                <Route path="/terms" element={<Terms />} />
                <Route path="/privacy" element={<Privacy />} />
            </Routes>
        </Router>

    </div>
  );
}

export default App;
